import React from 'react'

const NotFound = () => {

  return (

    <div style={{ minHeight: '80vh' }}>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
    </div>

  )
}

export default NotFound