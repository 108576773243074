export const WORD_LIST_REQUEST = 'WORD_LIST_REQUEST' 
export const WORD_LIST_SUCCESS = 'WORD_LIST_SUCCESS'
export const WORD_LIST_FAIL = 'WORD_LIST_FAIL'
export const WORD_LIST_RESET = 'WORD_LIST_RESET'

export const WORD_LANGUAGE_REQUEST = 'WORD_LANGUAGE_REQUEST'
export const WORD_LANGUAGE_SUCCESS = 'WORD_LANGUAGE_SUCCESS'
export const WORD_LANGUAGE_FAIL = 'WORD_LANGUAGE_FAIL'

export const WORD_RANDOM_REQUEST = 'WORD_RANDOM_REQUEST'
export const WORD_RANDOM_SUCCESS = 'WORD_RANDOM_SUCCESS'
export const WORD_RANDOM_FAIL = 'WORD_RANDOM_FAIL'
export const WORD_RANDOM_RESET = 'WORD_RANDOM_RESET'

export const WORD_RANDOM_LANGUAGE_REQUEST = 'WORD_RANDOM_LANGUAGE_REQUEST'
export const WORD_RANDOM_LANGUAGE_SUCCESS = 'WORD_RANDOM_LANGUAGE_SUCCESS'
export const WORD_RANDOM_LANGUAGE_FAIL = 'WORD_RANDOM_LANGUAGE_FAIL'

export const UPDATE_WORD_SCORE_REQUEST = 'UPDATE_WORD_SCORE_REQUEST'
export const UPDATE_WORD_SCORE_SUCCESS = 'UPDATE_WORD_SCORE_SUCCESS'
export const UPDATE_WORD_SCORE_FAIL = 'UPDATE_WORD_SCORE_FAIL'
export const UPDATE_WORD_SCORE_RESET = 'UPDATE_WORD_SCORE_RESET'