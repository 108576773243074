export const ADD_ALPHABET_REQUEST = 'ADD_ALPHABET_REQUEST'
export const ADD_ALPHABET_SUCCESS = 'ADD_ALPHABET_SUCCESS'
export const ADD_ALPHABET_FAIL = 'ADD_ALPHABET_FAIL'

export const GET_ALL_ALPHABETS_REQUEST = 'GET_ALL_ALPHABETS_REQUEST'
export const GET_ALL_ALPHABETS_SUCCESS = 'GET_ALL_ALPHABETS_SUCCESS'
export const GET_ALL_ALPHABETS_FAIL = 'GET_ALL_ALPHABETS_FAIL'

export const GET_ALL_ALPHABETS_LANGUAGE_REQUEST = 'GET_ALL_ALPHABETS_LANGUAGE_REQUEST'
export const GET_ALL_ALPHABETS_LANGUAGE_SUCCESS = 'GET_ALL_ALPHABETS_LANGUAGE_SUCCESS'
export const GET_ALL_ALPHABETS_LANGUAGE_FAIL = 'GET_ALL_ALPHABETS_LANGUAGE_FAIL'

export const GET_ALL_CHARS_LANGUAGE_REQUEST = 'GET_ALL_CHARS_LANGUAGE_REQUEST'
export const GET_ALL_CHARS_LANGUAGE_SUCCESS = 'GET_ALL_CHARS_LANGUAGE_SUCCESS'
export const GET_ALL_CHARS_LANGUAGE_FAIL = 'GET_ALL_CHARS_LANGUAGE_FAIL'

export const GET_NOT_MASTERED_USER_CHARS_REQUEST = 'GET_NOT_MASTERED_USER_CHARS_REQUEST'
export const GET_NOT_MASTERED_USER_CHARS_SUCCESS = 'GET_NOT_MASTERED_USER_CHARS_SUCCESS'
export const GET_NOT_MASTERED_USER_CHARS_FAIL = 'GET_NOT_MASTERED_USER_CHARS_FAIL'
export const RESET_NOT_MASTERED_CHARS = 'RESET_NOT_MASTERED_CHARS'

export const GET_MASTERED_USER_CHARS_REQUEST = 'GET_MASTERED_USER_CHARS_REQUEST'
export const GET_MASTERED_USER_CHARS_SUCCESS = 'GET_MASTERED_USER_CHARS_SUCCESS'
export const GET_MASTERED_USER_CHARS_FAIL = 'GET_MASTERED_USER_CHARS_FAIL'
export const RESET_MASTERED_CHARS = 'RESET_MASTERED_CHARS'