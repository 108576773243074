export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST' 
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_STATS_REQUEST = 'USER_STATS_REQUEST'
export const USER_STATS_SUCCESS = 'USER_STATS_SUCCESS'
export const USER_STATS_FAIL = 'USER_STATS_FAIL'
export const USER_STATS_RESET = 'USER_STATS_RESET'

export const ACTIVE_USER_REQUEST = 'ACTIVE_USER_REQUEST'
export const ACTIVE_USER_SUCCESS = 'ACTIVE_USER_SUCCESS'
export const ACTIVE_USER_FAIL = 'ACTIVE_USER_FAIL'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET '

export const ADD_LANGUAGE_TO_USER_REQUEST = 'ADD_LANGUAGE_TO_USER_REQUEST'
export const ADD_LANGUAGE_TO_USER_SUCCESS = 'ADD_LANGUAGE_TO_USER_SUCCESS'
export const ADD_LANGUAGE_TO_USER_FAIL = 'ADD_LANGUAGE_TO_USER_FAIL'

export const USER_WORDS_LANGUAGE_REQUEST = 'USER_WORDS_LANGUAGE_REQUEST'
export const USER_WORDS_LANGUAGE_SUCCESS = 'USER_WORDS_LANGUAGE_SUCCESS'
export const USER_WORDS_LANGUAGE_FAIL = 'USER_WORDS_LANGUAGE_FAIL'
export const USER_WORDS_LANGUAGE_RESET = 'USER_WORDS_LANGUAGE_RESET'

export const MASTERED_WORDS_REQUEST = 'MASTERED_WORDS_REQUEST'
export const MASTERED_WORDS_SUCCESS = 'MASTERED_WORDS_SUCCESS'
export const MASTERED_WORDS_FAIL = 'MASTERED_WORDS_FAIL'
export const MASTERED_WORDS_RESET = 'MASTERED_WORDS_RESET'
export const UPDATE_MASTERED_WORDS = 'UPDATE_MASTERED_WORDS'

export const NOT_MASTERED_WORDS_REQUEST = 'NOT_MASTERED_WORDS_REQUEST'
export const NOT_MASTERED_WORDS_SUCCESS = 'NOT_MASTERED_WORDS_SUCCESS'
export const NOT_MASTERED_WORDS_FAIL = 'NOT_MASTERED_WORDS_FAIL'
export const NOT_MASTERED_WORDS_RESET = 'NOT_MASTERED_WORDS_RESET'
export const UPDATE_NOT_MASTERED_WORDS = 'UPDATE_NOT_MASTERED_WORDS'

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAIL = 'ADD_USER_FAIL'

export const USER_ACTIVATION_REQUEST = 'USER_ACTIVATION_REQUEST'
export const USER_ACTIVATION_SUCCESS = 'USER_ACTIVATION_SUCCESS'
export const USER_ACTIVATION_FAIL = 'USER_ACTIVATION_FAIL'

export const USER_FROM_TOKEN_REQUEST = 'USER_FROM_TOKEN_REQUEST'
export const USER_FROM_TOKEN_SUCCESS = 'USER_FROM_TOKEN_SUCCESS'
export const USER_FROM_TOKEN_FAIL = 'USER_FROM_TOKEN_FAIL'

export const UPDATE_USER_LIST = 'UPDATE_USER_LIST'

export const SUBMIT_BUG_REPORT_REQUEST = 'SUBMIT_BUG_REPORT_REQUEST'
export const SUBMIT_BUG_REPORT_SUCCESS = 'SUBMIT_BUG_REPORT_SUCCESS'
export const SUBMIT_BUG_REPORT_FAIL = 'SUBMIT_BUG_REPORT_FAIL'

export const UPDATE_CONFIRM_RULES_REQUEST = 'UPDATE_CONFIRM_RULES_REQUEST'
export const UPDATE_CONFIRM_RULES_SUCCESS = 'UPDATE_CONFIRM_RULES_SUCCESS'
export const UPDATE_CONFIRM_RULES_FAIL = 'UPDATE_CONFIRM_RULES_FAIL'
